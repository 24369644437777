const initialState = {
  users: [],
  endUsers: [],
  isUserLoading: false,
  totalRows: 0,
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_LOADING":
      return {
        ...state,
        isUserLoading: true,
      };
    case "GET_USERS_ERROR":
      return {
        ...state,
        isUserLoading: false,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        users: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "ADD_USER_LOADING":
      return {
        ...state,
        isUserLoading: true,
      };
    case "ADD_USER_ERROR":
      return {
        ...state,
        isUserLoading: false,
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        users: [action.payload, ...state.users],
      };
    case "GET_END_USERS_LOADING":
      return {
        ...state,
        isUserLoading: true,
      };
    case "GET_END_USERS_ERROR":
      return {
        ...state,
        isUserLoading: false,
      };
    case "GET_END_USERS_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        endUsers: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "UPDATE_USER_LOADING":
      return {
        ...state,
        isUserLoading: true,
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        users: state.users?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case "UPDATE_USER_ERROR":
      return {
        ...state,
        isUserLoading: false,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        users: state.users?.filter((dat) => dat?.id !== action?.payload),
      };
    case "DELETE_USEREND_SUCCESS":
      return {
        ...state,
        isUserLoading: false,
        endUsers: state.endUsers?.filter((dat) => dat?.id !== action?.payload),
      };
    default:
      return state;
  }
};
