import { ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
// import "./styles/custom.css"
import Pages from "./routes";

function App() {
  const theme = createTheme({
    // Customize your theme here
    palette: {
      primary: {
        main: "#ED2A4D", // Red color
        background: "#FEF4F6",
      },
      secondary: {
        main: "#000000", // black color
      },
      background: {
        default: "#fff", // White color
      },
    },
    typography: {
      fontFamily: 'Spline Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
  });
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        {/* <CssBaseline /> */}
        <Pages />
      </ThemeProvider>
    </div>
  );
}

export default App;
