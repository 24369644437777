import {
  addPostApi,
  deletePostApi,
  getPostBySlugApi,
  getPostsApi,
  postApproveApi,
  updatePostApi,
} from "../../apis/services/postsApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_POSTS_LOADING: "GET_POSTS_LOADING",
  GET_POSTS_ERROR: "GET_POSTS_ERROR",
  GET_POSTS_SUCCESS: "GET_POSTS_SUCCESS",
  ADD_POSTS_LOADING: "ADD_POSTS_LOADING",
  ADD_POSTS_ERROR: "ADD_POSTS_ERROR",
  ADD_POSTS_SUCCESS: "ADD_POSTS_SUCCESS",
  UPDATE_POSTS_LOADING: "UPDATE_POSTS_LOADING",
  UPDATE_POSTS_ERROR: "UPDATE_POSTS_ERROR",
  UPDATE_POSTS_SUCCESS: "UPDATE_POSTS_SUCCESS",
  DELETE_POSTS_LOADING: "DELETE_POSTS_LOADING",
  DELETE_POSTS_SUCCESS: "DELETE_POSTS_SUCCESS",
  DELETE_POSTS_ERROR: "DELETE_POSTS_ERROR",
  GET_POST_BY_SLUG_LOADING: "GET_POST_BY_SLUG_LOADING",
  GET_POST_BY_SLUG_ERROR: "GET_POST_BY_SLUG_ERROR",
  GET_POST_BY_SLUG_SUCCESS: "GET_POST_BY_SLUG_SUCCESS",
};

export const getPosts = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_POSTS_LOADING });
    getPostsApi(payload)
      .then((response) => {
        const data = response.data?.posts?.data || [];
        const totalRows = response.data?.posts?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_POSTS_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_POSTS_ERROR });
      });
  };
};

export const getPostBySlug = (slug) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_POSTS_LOADING });
    getPostBySlugApi(slug)
      .then((response) => {
        const data = response.data?.post || [];
        dispatch({
          type: ActionTypes.GET_POST_BY_SLUG_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_POSTS_ERROR });
      });
  };
};

export const addPost = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_POSTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addPostApi(data)
      .then((response) => {
        const addedPost = response.data?.post;
        dispatch({
          type: ActionTypes.ADD_POSTS_SUCCESS,
          payload: addedPost,
        });
        if (callback) {
          callback();
        }
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        if (callback) {
          callback(true);
        }
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_POSTS_ERROR });
      });
  };
};

export const updatePost = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_POSTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updatePostApi(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);

        const updatedPost = response.data?.post;
        dispatch({
          type: ActionTypes.UPDATE_POSTS_SUCCESS,
          payload: updatedPost,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_POSTS_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};
export const approvePost = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_POSTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    postApproveApi(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        const updatedPost = response.data?.post;
        dispatch({
          type: ActionTypes.UPDATE_POSTS_SUCCESS,
          payload: updatedPost,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_POSTS_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const deletePost = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_POSTS_LOADING });
    deletePostApi(data?.slug)
      .then((response) => {
        const postId = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_POSTS_SUCCESS,
          payload: data?.slug,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_POSTS_ERROR });
      });
  };
};
