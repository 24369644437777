const initialState = {
  subscriptions: [],
  isLoading: false,
  statusMessage: null,
  paymentModal: false,
  paymentStatus: null,
  clientSecret: null,
  subscriptionList: [],
  invoiceList: [],
  paymentMethods: [],
};

export const payment = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_PAYMENT_MODAL":
      return {
        ...state,
        paymentModal: true,
      };
    case "CLOSE_PAYMENT_MODAL":
      return {
        ...state,
        paymentModal: false,
      };
    case "GET_SUBSCRIPTIONS_SUCCESS":
      return {
        ...state,
        subscriptions: action.payload,
        isLoading: false,
      };
    case "GET_SUBSCRIPTIONS_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "SEND_INVOICE_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Sending invoice",
      };
    case "SEND_INVOICE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: action.payload?.message,
        paymentStatus: action.payload?.organisation_subscription_data?.status,
      };
    case "SEND_INVOICE_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "SEND_CHANGE_PLAN_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Updating Subscription Plan",
      };
    case "SEND_CHANGE_PLAN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: action.payload?.message,
        paymentStatus: action.payload?.organisation_subscription_data?.status,
      };
    case "SEND_CHANGE_PLAN_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "UPDATE_PAYMENT_STATE":
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case "GET_INTENT_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "",
      };
    case "GET_INTENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        clientSecret: action.payload?.client_secret,
      };
    case "GET_INTENT_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "GET_SUBSCRIPTIONS_LIST_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Fetching Subscriptions",
      };
    case "GET_SUBSCRIPTIONS_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        subscriptionList: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "GET_SUBSCRIPTIONS_LIST_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "GET_INVOICES_LIST_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Fetching Invoice",
      };
    case "GET_INVOICES_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        invoiceList: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "GET_INVOICES_LIST_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "CANCEL_SUBSCRIPTION_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Cancelling Subscriptions",
      };
    case "CANCEL_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        clientSecret: null,
      };
    case "CANCEL_SUBSCRIPTION_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "MARK_AS_PAID_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Updating Invoice",
      };
    case "MARK_AS_PAID_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "MARK_AS_PAID_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "ACTIVATE_SUBSCRIPTION_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Activating Subscriptions",
      };
    case "ACTIVATE_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "ACTIVATE_SUBSCRIPTION_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_PAYMENT_METHOD_LIST_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Fetching Card details",
      };
    case "GET_PAYMENT_METHOD_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        paymentMethods: action.payload,
      };
    case "GET_PAYMENT_METHOD_LIST_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "UPDATE_DEFAULT_PAYMENT_METHOD_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Updating Default Payment method",
      };
    case "UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        paymentMethods: action.payload,
      };
    case "UPDATE_DEFAULT_PAYMENT_METHOD_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "UPDATE_PAYMENT_METHOD_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Updating Payment Method",
      };
    case "UPDATE_PAYMENT_METHOD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
        paymentMethods: action.payload,
      };
    case "UPDATE_PAYMENT_METHOD_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "DELETE_PAYMENT_METHOD_LOADING":
      return {
        ...state,
        isLoading: true,
        statusMessage: "Deleting Payment Method",
      };
    case "DELETE_PAYMENT_METHOD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    case "DELETE_PAYMENT_METHOD_ERROR":
      return {
        ...state,
        isLoading: false,
        statusMessage: null,
      };
    default:
      return state;
  }
};

// Selector function
export const selectPaymentModal = (state) => state.payment.paymentModal;
export const selectIsLoading = (state) => state.payment.isLoading;
export const selectStatusMessage = (state) => state.payment.statusMessage;
export const selectSubscriptions = (state) => state.payment.subscriptions;
export const selectPaymentStatus = (state) => state.payment.paymentStatus;
