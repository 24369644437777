import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Badge,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  updateNotification,
} from "../../redux/shots/actions";
import { isArrayEmpty } from "../../helpers/commonUtils";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 700,
  },
}));

const NotificationText = styled(ListItemText)(({ theme }) => ({
  width: "525px", // 75% of 700px
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: "300px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "6px", // Adjust the width as needed
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
}));

const NoNotificationsText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const { notifications, isNotificationLoading } = useSelector(
    (data) => data.shot
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificaitonUpdate = async (
    notification_identifier,
    target_identifier,
    allowed_action
  ) => {
    const data = {
      notification_identifier: notification_identifier,
      target_identifier: target_identifier,
      action: allowed_action?.key,
    };

    try {
      // Dispatch the update notification action
      // Dispatch the update notification action
      await dispatch(
        updateNotification(data, () => {
          // Callback function to call getNotifications after updateNotification is complete
          dispatch(getNotifications());
        })
      );
    } catch (error) {
      console.error("Action failed:", error);
    }
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <Fragment>
      <IconButton
        color="inherit"
        onClick={handleOpen}
        sx={{ fontSize: "28px",marginRight:'12px' }}
      >
        <Badge
          badgeContent={
            isArrayEmpty(notifications) ? null : notifications.length
          }
          color="success"
          invisible={isArrayEmpty(notifications)} // Hide the badge if there are no notifications
        >
          <NotificationsIcon fontSize="23px" />
        </Badge>
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Notifications</Typography>
          {isNotificationLoading && <CircularProgress size={24} />}
        </Box>
        <Divider />
        <ScrollableBox
          style={{
            pointerEvents: isNotificationLoading ? "none" : "auto", // Disable clicks if loading
            opacity: isNotificationLoading ? 0.5 : 1, // Fade out effect
            transition: "opacity 0.3s ease", // Smooth transition
          }}
        >
          {isArrayEmpty(notifications) ? (
            <NoNotificationsText>No Notifications</NoNotificationsText>
          ) : (
            notifications.map(
              ({
                key,
                notification_message,
                notification_image,
                notification_time,
                notification_identifier,
                target_identifier,
                allowed_actions,
              }) => (
                <Box key={key}>
                  <MenuItem>
                    <ListItem alignItems="flex-start">
                      {notification_image ? (
                        <ListItemAvatar>
                          <Avatar
                            alt={notification_message}
                            src={notification_image}
                          />
                        </ListItemAvatar>
                      ) : null}

                      <NotificationText
                        primary={notification_message}
                        secondary={
                          <>
                            {notification_time}
                            <Box mt={2}>
                              {allowed_actions.map((allowed_action) => {
                                const uniqueKey = `${notification_identifier}-${allowed_action.key}-${key}`;
                                return (
                                  <Button
                                    key={uniqueKey} // Use the unique key for each button
                                    variant="contained"
                                    sx={{
                                      backgroundColor:
                                        allowed_action?.button_color,
                                    }}
                                    size="small"
                                    onClick={() =>
                                      handleNotificaitonUpdate(
                                        notification_identifier,
                                        target_identifier,
                                        allowed_action,
                                        key
                                      )
                                    }
                                    style={{
                                      marginRight: "8px",
                                      position: "relative",
                                    }} // Disable button if loading
                                  >
                                    {allowed_action.value}
                                  </Button>
                                );
                              })}
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                  </MenuItem>
                  <Divider />
                </Box>
              )
            )
          )}
        </ScrollableBox>
      </StyledMenu>
    </Fragment>
  );
};

export default NotificationDropdown;
