import { generateURL } from "../../helpers/commonUtils";
import apis, { formAxios } from "../index";

export const getHubsAPI = async (payload) => {
  try {
    let url = generateURL("hub", payload);
    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const addHubsAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("hub", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const updateHubsAPI = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post(`hub/${id}`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const deleteHubsAPI = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(`hub/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};
