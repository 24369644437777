import { generateURL } from "../../helpers/commonUtils";
import apis from "../index";

export const getProductsAPI = async (payload) => {
  try {
    let url = generateURL("subscription", payload);
    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getSkuAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("subscription/generate-sku", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const addProductsAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("subscription", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const updateProductsAPI = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.put(`subscription/${id}`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const deleteProductsAPI = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(`subscription/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};
