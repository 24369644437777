import { lazy } from "react";

const routes = [
  {
    path: "/dashboard",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/dashboard/Index")),
  },
  {
    path: "/categories",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/categories/index")),
  },
  {
    path: "/products",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/products/index")),
  },
  {
    path: "/activities",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/activities/index")),
  },
  {
    path: "/passions",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/passions/index")),
  },
  {
    path: "/user-management",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/userManagement/index")),
  },
  {
    path: "/user",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/user/index")),
  },
  {
    path: "/organisations",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/organizations/index")),
  },
  {
    path: "/reports",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/reports/index")),
  },
  {
    path: "/billing",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/billing/index")),
  },
  {
    path: "/hubs",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/hubs/index")),
  },
  {
    path: "/hubs/:id",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/hubs/HubDetails")),
  },
  {
    path: "/featured-brands",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/brands/index")),
  },
  {
    path: "/cms",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cms/index")),
  },
  {
    path: "/posts",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cms/posts/index")),
  },
  {
    path: "/posts/details",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cms/posts/PostAddEdit")),
  },
  {
    path: "/pages/details",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cms/pages/pageAddEdit")),
  },
  {
    path: "/pages",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cms/pages/index")),
  },
  {
    path: "/profile",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/profile/index")),
  },
  {
    path: "/shots",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/shots/index")),
  },
  {
    path: "/shots/details",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/shots/ShotAddEdit")),
  },
  {
    path: "/registration",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/registration/index")),
  },
  {
    path: "/shots/:slug",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/shots/Slug")),
  },
  {
    path: "/featured",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/featured/index")),
  },
  {
    path: "/payments",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/payments/index")),
  },
  {
    path: "/profile-managment",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/profileManagment/index")),
  },
  {
    path: "/settings",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/settings/index")),
  },
];

export default routes;
