import apis from "../index";

export const getCommonDropDownValuesAPI = async (payload) => {
  try {
    // Call your API endpoint with the constructed URL
    const response = await apis.get("filter-configurations", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching common values:", error);
    throw error;
  }
};
