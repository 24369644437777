const initialState = {
  organization: [],
  categories: [],
  isMyOrgLoading: false,
  isListLoading: false,
  myOrgDetails: undefined,
  myOrgInfo:undefined
};

export const organization = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MY_ORGANIZATION_DETAILS_LOADING":
      return {
        ...state,
        isMyOrgLoading: true,
      };
      case "GET_MY_ORGANIZATION_INFO_LOADING":
      return {
        ...state,
        isMyOrgLoading: true,
      };
    case "MY_ORGANIZATION_LOADING":
      return {
        ...state,
        isMyOrgLoading: true,
      };
    case "ADD_ORGANIZATION_LOADING":
      return {
        ...state,
        isMyOrgLoading: true,
      };
    case "GET_MY_ORGANIZATION_DETAILS_ERROR":
      return {
        ...state,
        isMyOrgLoading: false,
      };
      case "GET_MY_ORGANIZATION_INFO_ERROR":
      return {
        ...state,
        isMyOrgLoading: false,
      };
    case "ADD_ORGANIZATION_ERROR":
      return {
        ...state,
        isMyOrgLoading: false,
      };

    case "GET_ORGANIZATION_LOADING":
      return {
        ...state,
        isListLoading: true,
      };
    case "GET_ORGANIZATION_ERROR":
      return {
        ...state,
        isListLoading: false,
      };
    case "GET_ORGANIZATION_SUCCESS":
      return {
        ...state,
        isListLoading: false,
        organization: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "GET_MY_ORGANIZATION_DETAILS_SUCCESS":
      return {
        ...state,
        isMyOrgLoading: false,
        myOrgDetails: action.payload,
      };
      case "GET_MY_ORGANIZATION_INFO_SUCCESS":
      return {
        ...state,
        isMyOrgLoading: false,
        myOrgInfo: action.payload,
      };
    case "GET_ORG_CATEGORY_SUCCESS":
      return {
        ...state,
        categories: action.payload,
      };
    case "ADD_ORGANIZATION_SUCCESS":
      return {
        ...state,
        organization: [action.payload, ...state.organization],
        isMyOrgLoading: false,
      };
    case "DELETE_ORGANIZATION":
      return {
        ...state,
        organization: state.organization.filter(
          (org) => org.id !== action.payload
        ),
        isMyOrgLoading: false,
      };
    case "UPDATE_ORGANIZATION_SUCCESS":
      return {
        ...state,
        organization: state.organization.map((org) =>
          org.id === action.payload.id ? action.payload : org
        ),
        isMyOrgLoading: false,
      };
    default:
      return state;
  }
};
