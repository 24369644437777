import {
  getShotBySlugStatusAPI,
  updateShotsStatusAPI,
  getBySlugAPI,
  addShotsAPI,
  deleteShotsAPI,
  getShotsAPI,
  updateShotsAPI,
  getShotBySlugAPI,
  updateApplicationStatusAPI,
  featuredShotsAPI,
  getShotDistributionAPI,
  updateShotDistributionAPI,
  getHubsShotsAPI,
  getNotificationsAPI,
  updateNotificationAPI,
} from "../../apis/services/shotsApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_SHOTS_LOADING: "GET_SHOTS_LOADING",
  GET_SHOTS_ERROR: "GET_SHOTS_ERROR",
  GET_SHOTS_SUCCESS: "GET_SHOTS_SUCCESS",
  ADD_SHOT_LOADING: "ADD_SHOT_LOADING",
  ADD_SHOT_ERROR: "ADD_SHOT_ERROR",
  ADD_SHOT_SUCCESS: "ADD_SHOT_SUCCESS",
  UPDATE_SHOT_LOADING: "UPDATE_SHOT_LOADING",
  UPDATE_SHOT_ERROR: "UPDATE_SHOT_ERROR",
  UPDATE_SHOT_SUCCESS: "UPDATE_SHOT_SUCCESS",
  DELETE_SHOT_LOADING: "DELETE_SHOT_LOADING",
  DELETE_SHOT_SUCCESS: "DELETE_SHOT_SUCCESS",
  DELETE_SHOT_ERROR: "DELETE_SHOT_ERROR",
  GET_SHOTS_SLUG_SUCCESS: "GET_SHOTS_SLUG_SUCCESS",
  UPDATE_SHOT_FEATURED: "UPDATE_SHOT_FEATURED",
  UPDATE_SHOTS_SLUG_SUCCESS: "UPDATE_SHOTS_SLUG_SUCCESS",
  SHOT_LOADING_SUCCESS: "SHOT_LOADING_SUCCESS",
  GET_SHOT_DISTRIBUTION_LOADING: "GET_SHOT_DISTRIBUTION_LOADING",
  GET_SHOT_DISTRIBUTION_ERROR: "GET_SHOT_DISTRIBUTION_ERROR",
  GET_SHOT_DISTRIBUTION_SUCCESS: "GET_SHOT_DISTRIBUTION_SUCCESS",
  UPDATE_SHOT_DISTRIBUTION_LOADING: "UPDATE_SHOT_DISTRIBUTION_LOADING",
  UPDATE_SHOT_DISTRIBUTION_ERROR: "UPDATE_SHOT_DISTRIBUTION_ERROR",
  UPDATE_SHOT_DISTRIBUTION_SUCCESS: "UPDATE_SHOT_DISTRIBUTION_SUCCESS",
  GET_NOTIFICATION_LOADING: "GET_NOTIFICATION_LOADING",
  GET_NOTIFICATION_ERROR: "GET_NOTIFICATION_ERROR",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_LOADING: "UPDATE_NOTIFICATION_LOADING",
  UPDATE_NOTIFICATION_ERROR: "UPDATE_NOTIFICATION_ERROR",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
};

export const getSlug = (slug, callBack) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    getBySlugAPI(slug)
      .then((response) => {
        const hubsData = response.data?.shot || [];
        callBack(hubsData);
        dispatch({ type: ActionTypes?.SHOT_LOADING_SUCCESS });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOTS_ERROR });
      });
  };
};

export const updateApplicantBySlug = (row, id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    getShotBySlugStatusAPI(id, row)
      .then((response) => {
        const hubsData = response.data?.application || [];
        dispatch({
          type: ActionTypes.UPDATE_SHOTS_SLUG_SUCCESS,
          payload: hubsData,
        });
        CommonToast.notify(
          "success",
          response.data?.message || "Updated Successfully"
        );
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOTS_ERROR });
      });
  };
};

export const getShotBySlug = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    getShotBySlugAPI(payload)
      .then((response) => {
        const data = response.data?.applications?.data || [];
        const totalRows = response.data?.applications?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_SHOTS_SLUG_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOTS_ERROR });
      });
  };
};

export const getShots = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    getShotsAPI(payload)
      .then((response) => {
        const data = response.data?.shots?.data || [];
        const totalRows = response.data?.shots?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_SHOTS_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOTS_ERROR });
      });
  };
};
export const getHubsShots = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    getHubsShotsAPI(payload)
      .then((response) => {
        const data = response.data?.shots?.data || [];
        const totalRows = response.data?.shots?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_SHOTS_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOTS_ERROR });
      });
  };
};

export const addShot = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_SHOT_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addShotsAPI(data)
      .then((response) => {
        const updatedHubs = response?.data?.shot;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.ADD_SHOT_SUCCESS,
          payload: updatedHubs,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_SHOT_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const updateShot = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_SHOT_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateShotsAPI(data, id)
      .then((response) => {
        const hubData = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_SHOT_SUCCESS,
          payload: hubData?.shot,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_SHOT_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const updateShotStatus = (data, id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_SHOT_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateShotsStatusAPI(data, id)
      .then((response) => {
        const hubData = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_SHOT_SUCCESS,
          payload: hubData?.shot,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_SHOT_ERROR });
      });
  };
};

export const updateShotFeatured = (slug, isFeatured, id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    featuredShotsAPI(slug, isFeatured)
      .then((response) => {
        const shotData = response.data?.shot;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_SHOT_FEATURED,
          payload: shotData,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_SHOT_ERROR });
      });
  };
};

export const updateApplicationStatus = (data, id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_SHOT_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateApplicationStatusAPI(data, id)
      .then((response) => {
        const hubData = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_SHOT_SUCCESS,
          payload: hubData?.shot,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_SHOT_ERROR });
      });
  };
};

export const deleteShot = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_SHOT_LOADING });
    deleteShotsAPI(id)
      .then((response) => {
        const hubId = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_SHOT_SUCCESS,
          payload: id,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_SHOT_ERROR });
      });
  };
};

export const getShotDistribution = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SHOT_DISTRIBUTION_LOADING });
    getShotDistributionAPI()
      .then((response) => {
        const shotDistrubution = response.data?.data || [];
        dispatch({
          type: ActionTypes.GET_SHOT_DISTRIBUTION_SUCCESS,
          payload: shotDistrubution,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SHOT_DISTRIBUTION_ERROR });
      });
  };
};

export const getNotifications = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_NOTIFICATION_LOADING });
    getNotificationsAPI()
      .then((response) => {
        const notifications = response.data?.data || [];
        dispatch({
          type: ActionTypes.GET_NOTIFICATION_SUCCESS,
          payload: notifications,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_NOTIFICATION_ERROR });
      });
  };
};

export const updateNotification = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_NOTIFICATION_LOADING });
    updateNotificationAPI(payload)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.UPDATE_NOTIFICATION_ERROR });
        if (callback) {
          callback();
        }
      });
  };
};

export const updateShotDistribution = (id, actionStatus, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SHOT_DISTRIBUTION_LOADING });
    updateShotDistributionAPI(id, actionStatus)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_SHOT_DISTRIBUTION_SUCCESS,
        });
        if (callback) {
          callback(actionStatus);
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.UPDATE_SHOT_DISTRIBUTION_ERROR });
        if (callback) {
          callback(actionStatus);
        }
      });
  };
};
