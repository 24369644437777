import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';


function index({ isActive, handleClose, onDelete, alertMessage, alertHeadder, okButton, cancelButton }) {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog
      open={typeof isActive === "number" || isActive}
      // TransitionComponent={Transition}
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "24px", padding: "40px 32px" } }}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">{alertHeadder || `Delete`}</DialogTitle> */}
      <div className='modal-header' id="dialog-title">{alertHeadder || `Delete`}</div>

      <DialogContent sx={{ padding: 0, display: "flex", flexDirection: "column", gap: "20px" }}>
        {alertMessage || `Are you want to delete this item`}
        <DialogActions sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button sx={{ borderRadius: "43px", border: "1px solid black", padding: "15px 30px" }} onClick={handleClose} color="secondary" >
            {cancelButton ?? "Cancel"}
          </Button>
          <Button sx={{ borderRadius: "43px", background: "#ED2A4D", padding: "17px 40px" }} onClick={() => onDelete(isActive)} color="primary" variant="contained" autoFocus>
            {okButton}
          </Button>
        </DialogActions>
      </DialogContent>

    </Dialog>
  )
}

export default index