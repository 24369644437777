import { getCommonDropDownValuesAPI } from "../../apis/services/commonApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_COMMON_DROPDOWN_LOADING: "GET_COMMON_DROPDOWN_LOADING",
  GET_COMMON_DROPDOWN_ERROR: "GET_COMMON_DROPDOWN_ERROR",
  GET_COMMON_DROPDOWN_SUCCESS: "GET_COMMON_DROPDOWN_SUCCESS",
};

export const getCommonDropDownValues = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_COMMON_DROPDOWN_LOADING });
    getCommonDropDownValuesAPI()
      .then((response) => {
        let dropDownData = response.data.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.GET_COMMON_DROPDOWN_SUCCESS,
          payload: dropDownData,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_COMMON_DROPDOWN_ERROR });
      });
  };
};
