import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify'
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react'
import theme from "./components/Theme";

const persistor = persistStore(store);

const root = document.getElementById('root');

const setPrimaryColorCSSVariable = () => {
  const primaryColor = theme.palette.primary.main;
  document.documentElement.style.setProperty("--primary-color", primaryColor);
};

setPrimaryColorCSSVariable();

ReactDOM.render(
  // <React.StrictMode>
  <>
    <Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
    </>,
    root
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
