const initialState = {
  passions: [],
  flagopen: true,
  isLoading: false,
  isListLoading: false,
  totalRows: 0,
};

export const passion = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PASSIONS_LOADING":
      return {
        ...state,
        isListLoading: true,
      };
    case "GET_PASSIONS_ERROR":
      return {
        ...state,
        isListLoading: false,
      };
    case "GET_PASSIONS_SUCCESS":
      return {
        ...state,
        isListLoading: false,
        passions: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case "ADD_PASSION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        passions: [...action.payload, ...state.passions],
      };
    case "UPDATE_PASSION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        passions: state.passions?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case "ARCHIVE_PASSION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        passions: state.passions?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    default:
      return state;
  }
};
