// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ED2A4D", // Red color
    },
    secondary: {
      main: "#000000", // black color
    },
    background: {
      default: "#fff", // White color
    },
  },
});

export default theme;
