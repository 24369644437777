import {
  addMediaApi,
  deleteMediaApi,
  getMediaApi,
  getMediaByIdApi,
  updateMediaApi,
} from "../../apis/services/mediaApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_MEDIA_LOADING: "GET_MEDIA_LOADING",
  GET_MEDIA_ERROR: "GET_MEDIA_ERROR",
  GET_MEDIA_SUCCESS: "GET_MEDIA_SUCCESS",
  ADD_MEDIA_LOADING: "ADD_MEDIA_LOADING",
  ADD_MEDIA_ERROR: "ADD_MEDIA_ERROR",
  ADD_MEDIA_SUCCESS: "ADD_MEDIA_SUCCESS",
  UPDATE_MEDIA_LOADING: "UPDATE_MEDIA_LOADING",
  UPDATE_MEDIA_ERROR: "UPDATE_MEDIA_ERROR",
  UPDATE_MEDIA_SUCCESS: "UPDATE_MEDIA_SUCCESS",
  DELETE_MEDIA_LOADING: "DELETE_MEDIA_LOADING",
  DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
  DELETE_MEDIA_ERROR: "DELETE_MEDIA_ERROR",
  GET_MEDIA_BY_SLUG_SUCCESS: "GET_MEDIA_BY_SLUG_SUCCESS",
  GET_MEDIA_BY_SLUG_ERROR: "GET_MEDIA_BY_SLUG_ERROR",
};

export const getMedia = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_MEDIA_LOADING });
    getMediaApi(payload)
      .then((response) => {
        const data = response.data?.media?.data || [];
        const totalRows = response.data?.pages?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_MEDIA_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_MEDIA_ERROR });
      });
  };
};

export const getMediaById = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_MEDIA_LOADING });
    getMediaByIdApi(id)
      .then((response) => {
        const data = response.data?.page || [];
        dispatch({
          type: ActionTypes.GET_MEDIA_BY_SLUG_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_MEDIA_ERROR });
      });
  };
};

export const addMedia = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_MEDIA_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addMediaApi(data)
      .then((response) => {
        const addedPage = response.data?.media;
        dispatch({
          type: ActionTypes.ADD_MEDIA_SUCCESS,
          payload: addedPage,
        });
        if (callback) {
          callback();
        }
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        if (callback) {
          callback(true);
        }
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_MEDIA_ERROR });
      });
  };
};

export const updateMedia = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_MEDIA_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateMediaApi(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);

        const updatedPage = response.data?.media;
        dispatch({
          type: ActionTypes.UPDATE_MEDIA_SUCCESS,
          payload: updatedPage,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_MEDIA_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const deleteMedia = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_MEDIA_LOADING });
    deleteMediaApi(data?.id)
      .then((response) => {
        const postId = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_MEDIA_SUCCESS,
          payload: data?.id,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_MEDIA_ERROR });
      });
  };
};
