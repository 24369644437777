import moment from "moment";
import store from "../redux/store";

store.subscribe(() => {
  // Call your functions here whenever the store state changes
  const headers = getHeaders(store);
  // const formHeaders = getFormHeaders(store);
});
export const getHeaders = () => {
  // const token = store?.getState().auth?.userData?.token || localStorage.getItem('token') || ''
  return {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  };
};

export const getFormHeaders = () => {
  const token =
    store.getState().auth?.userData?.token ||
    localStorage.getItem("token") ||
    "";

  return {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
};

export function capitalizeFirstLetter(string = "") {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const getDropDownValue = (array, key) => {
  return Array.from(new Set(array.map((obj) => obj[key]))).map((value) => ({
    label: value,
    value: value,
  }));
};

export const dateFormat = (date) => {
  return date ? moment(date).format("DD-MM-YYYY") : null;
};

export const flattenObjectsWithMultipleNestedKeys = (objects, nestedKeys) => {
  if (!objects || !Array.isArray(objects)) {
    // Handle undefined, null, or non-array input gracefully
    return [];
  }
  const flattenNestedObject = (obj, nestedKey) => {
    if (!obj[nestedKey] || typeof obj[nestedKey] !== "object") {
      return {};
    }

    const flatObject = {};
    Object.keys(obj[nestedKey]).forEach((key) => {
      flatObject[`${nestedKey}_${key.slice(0)}`] = obj[nestedKey][key];
    });

    return flatObject;
  };
  return objects.map((obj) => {
    let flattenedObject = { ...obj };
    nestedKeys.forEach((nestedKey) => {
      const flatNestedObject = flattenNestedObject(obj, nestedKey);
      flattenedObject = { ...flattenedObject, ...flatNestedObject };
      delete flattenedObject[nestedKey];
    });
    return flattenedObject;
  });
};

export const isArrayEmpty = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    return false; // Array is not empty
  }
  return true; // Array is empty or not an array
};

export const generateURL = (baseURL, params) => {
  let url = params?.noPagination ? `${baseURL}${params?.filters ?"?" : '?'}` : `${baseURL}?paginate=1&`;

  if (params?.searchQuery) {
    url += `search[all]=${params.searchQuery}&`;
  }
  
  if (params?.filters) {
    // Add filters to the URL, ignoring null or empty values
    for (const [key, value] of Object.entries(params.filters)) {
      if (value !== null && value !== "") {
        url += `filters[${key}]=${value}&`;
      }
    }
  }
  if(params?.viewAll){
    url +='view_all=1&'
  }

  if (params?.sort) {
    url += `sort_order=asc&sort_key=name&`;
  }

  if (params?.page) {
    url += `page=${params.page}&`;
  }

  if (params?.pageSize) {
    url += `pagination_size=${params.pageSize}&`;
  }

  if (params?.role) {
    url += `role_key=${params.role}&`;
  }

  // Remove the trailing '&' from the URL
  url = url.endsWith("&") ? url.slice(0, -1) : url;

  return url;
};
