import { ActionTypes } from "./actions";

const initialState = {
  products: [],
  isLoading: false,
  totalRows: 0,
};

export const product = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes?.GET_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload?.data,
        totalRows: action.payload?.totalRows,
        isLoading: false,
      };
    case ActionTypes?.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: [action.payload, ...state.products],
      };
    case ActionTypes?.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: state.products?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: state.products?.filter(
          (dat) => dat?.id !== action?.payload?.id
        ),
      };
    default:
      return state;
  }
};
