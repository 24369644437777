const initialState = {
  categories: [],
  isLoading: false,
}

export const category = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CATEGORY_LOADING':
      return {
        ...state,
        isLoading: true,
      }
    case 'GET_CATEGORY_ERROR':
      return {
        ...state,
        isLoading: false,
      }
    case 'GET_CATEGORY_SUCCESS':
      return {
        ...state,
        categories: action.payload,
      }
    case 'ADD_CATEGORY_SUCCESS':
      return {
        ...state,
        categories: [action.payload, ...state.categories],
      }
    case 'UPDATE_CATEGORY_SUCCESS':
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.id ? action.payload : category
        ),
      }
    case 'DELETE_CATEGORY_SUCCESS':
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== action.payload
        ),
      }
    case 'ADD_SUB_CATEGORY_SUCCESS':
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.parent_id ? { ...category, subcategories: [action.payload, ...category?.subcategories] } : category
        ),
      }
    case 'UPDATE_SUBCATEGORY_SUCCESS':
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.parent_id ? {
            ...category, subcategories: category?.subcategories?.map((subCategory) =>
              subCategory.id === action.payload.id ? action.payload : subCategory
            )
          } : category
        ),
      }
    case 'DELETE_SUBCATEGORY_SUCCESS':
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.parent_id ? {
            ...category, subcategories: category?.subcategories?.filter(
              (subCategory) => subCategory.id !== action.payload.id
            )
          } : category
        ),
      }
    case 'ADD_CATEGORY_LOADING':
    default:
      return state
  }
}




