import { ActionTypes } from "./actions";

const initialState = {
  isPostsLoading: false,
  posts: undefined,
  totalRows: 0,
  selectedPost: undefined,
};

export const posts = (state = initialState, action) => {
  switch (action?.type) {
    case ActionTypes.GET_POSTS_LOADING:
      return {
        ...state,
        isPostsLoading: true,
      };
    case ActionTypes.ADD_POSTS_LOADING:
      return {
        ...state,
        isPostsLoading: true,
      };
    case ActionTypes.GET_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload?.data,
        totalRows: action.payload?.totalRows,
        isPostsLoading: false,
      };
    case ActionTypes.GET_POSTS_ERROR:
      return {
        ...state,
        isPostsLoading: false,
      };
    case ActionTypes.GET_POST_BY_SLUG_SUCCESS:
      return {
        ...state,
        isPostsLoading: false,
        selectedPost: action.payload,
      };
    case ActionTypes.GET_POST_BY_SLUG_ERROR:
      return {
        ...state,
        isPostsLoading: false,
      };
    case ActionTypes?.ADD_POSTS_SUCCESS:
      return {
        ...state,
        isPostsLoading: false,
        posts: [action.payload, ...state.posts],
      };
    case ActionTypes?.UPDATE_POSTS_SUCCESS:
      return {
        ...state,
        isPostsLoading: false,
        posts: state.posts?.map((dat) =>
          dat?.slug === action?.payload?.slug ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_POSTS_SUCCESS:
      return {
        ...state,
        isPostsLoading: false,
        posts: state.posts?.filter((dat) => dat?.slug !== action?.payload),
      };
    case ActionTypes.DELETE_POSTS_ERROR:
      return {
        ...state,
        isPostsLoading: false,
      };
    case ActionTypes.ADD_POSTS_ERROR:
      return {
        ...state,
        isPostsLoading: false,
      };
    case ActionTypes.UPDATE_POSTS_ERROR:
      return {
        ...state,
        isPostsLoading: false,
      };
    default:
      return state;
  }
};
