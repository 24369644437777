import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import RequireAuth from "../components/requreAuth/index";
import PrivateLayout from "../components/requreAuth/privateLayout";
import { Box, CircularProgress } from "@mui/material";
import MenuBar from "../components/menu/Index";

const Pages = () => {
  return (
    <Router basename="/">
      <Routes>
        {PublicRoutes.map((route, index) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              <Suspense fallback={<div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></div>}>
                <route.element />
              </Suspense>
            }
          />
        ))}
        <Route
          element={
            <RequireAuth>
              <PrivateLayout />
            </RequireAuth>
          }
        >
          {PrivateRoutes.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></div>}>
                  <route.element />
                </Suspense>
              }
            />
          ))}
        </Route>
      </Routes>
    </Router>
    // <Router>
    //   <div style={{ paddingTop: '2rem' }}>
    //     <Routes>
    //       {/* Public Routes */}
    //       {PublicRoutes.map((route, index) => (
    //         <Route
    //           key={index}
    //           path={route.path}
    //           element={
    //             <Suspense fallback={<div>Loading...</div>}>
    //               <route.element />
    //             </Suspense>
    //           }
    //         />
    //       ))}

    //       {/* Protected Routes */}
    //       {PrivateRoutes.map((route, index) => (
    //         <Route
    //           key={index}
    //           path={route.path}
    //           element={
    //             <RequireAuth>
    //               <Box sx={{ display: 'flex' }}>
    //                 <MenuBar/>
    //                 {/* <Box component="main"> */}
    //                   <Suspense fallback={<div>Loading...</div>}>
    //                     <route.element />
    //                   </Suspense>
    //                 {/* </Box> */}
    //               </Box>
    //             </RequireAuth>
    //           }
    //         />
    //       ))}
    //     </Routes>
    //   </div>
    // </Router>
  );
};

export default Pages;
