import { generateURL } from "../../helpers/commonUtils";
import apis, { formAxios } from "../index";

export const getShotsAPI = async (payload) => {
  try {
    let url = generateURL("shot", payload);
    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getHubsShotsAPI = async (payload) => {
  try {
    let url = generateURL(`hub/${payload?.slug.split("?")[0]}/shots`, payload);
    // Call your login API endpoint here
    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getShotBySlugAPI = async (payload) => {
  try {
    let url = generateURL(`shot/${payload?.slug}/applications`, payload);
    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getShotBySlugStatusAPI = async (id, row) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(`application/${id}/status`, {
      ...row,
      _method: "PUT",
    });
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getBySlugAPI = async (slug) => {
  try {
    // Call your login API endpoint here
    const response = await apis.get(`shot/${slug}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const addShotsAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("shot", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const featuredShotsAPI = async (slug, isFeatured) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(`shot/toggle-feature/${slug}`, {
      action: isFeatured,
    });
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching shot:", error);
    throw error;
  }
};

export const updateApplicationStatusAPI = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.put(`application/${id}/status`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const updateShotsAPI = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post(`shot/${id}`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const updateShotsStatusAPI = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(`shot/${id}/status`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const deleteShotsAPI = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(`shot/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getShotDistributionAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.get("/shot-distribution-requests", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const getNotificationsAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.get("/notifications", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching notifications:", error);
    throw error;
  }
};

export const updateShotDistributionAPI = async (id, actionStatus) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(
      `/shot-distribution-requests/${id}/${actionStatus}`
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching categories:", error);
    throw error;
  }
};

export const updateNotificationAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(`/notificaions/take-action`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during updating notifications:", error);
    throw error;
  }
};
