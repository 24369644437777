import React from "react";
import { Outlet } from "react-router-dom";
import MenuBar from "../menu/Index";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const PrivateLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <MenuBar />
      <Box
        component="main"
        sx={{
          width: { sm: `100%` }, // Adjust according to your drawer width
        }}
      >
        <Toolbar />
        <div className="py-1 px-4 content-background-clr">
          <Outlet />
        </div>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
