import { generateURL } from "../../helpers/commonUtils";
import apis from "../index";

export const getSubscriptionPlansAPI = async (categoryId) => {
  try {
    // Call your login API endpoint here
    const response = await apis.get(
      `subscription?filters[category_id]=${categoryId}&filters[is_enabled]=1`
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching subscriptions:", error);
    throw error;
  }
};

export const sendInvoiceAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("subscription/subscribe-plan", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during adding Passion:", error);
    throw error;
  }
};

export const changePlanAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("subscription/change-plan", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during adding Updation plan:", error);
    throw error;
  }
};

export const getIntentApi = async () => {
  try {
    // Call your login API endpoint here
    const response = await apis.get(`subscription/get-intent`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching subscriptions:", error);
    throw error;
  }
};

export const getSubscriptionListApi = async (payload) => {
  try {
    let url = generateURL("organisations/subscriptions", payload);
    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);

    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching subscriptions:", error);
    throw error;
  }
};

export const getInvoicesApi = async (payload) => {
  try {

    // let url = "invoices?paginate=1&";
    let url = generateURL("invoices", payload);

    // Conditionally add searchQuery, page, and pageSize to the URL
    // if (payload?.searchQuery) {
    //   url += `search[all]=${payload.searchQuery}&`;
    // } else {
    //   if (payload?.page) {
    //     url += `page=${payload.page}&`;
    //   }
    //   if (payload?.pageSize) {
    //     url += `pagination_size=${payload.pageSize}&`;
    //   }
    // }

    // Remove the trailing '&' from the URL
    url = url.endsWith("&") ? url.slice(0, -1) : url;

    // Call your API endpoint with the constructed URL
    const response = await apis.get(url, payload);

    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching subscriptions:", error);
    throw error;
  }
};

export const cancelSubscriptionApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(
      `subscription/${payload.organizationId}/update-subscription-status`,
      payload
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const activateSubscriptionApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(
      `subscription/${payload.organizationId}/update-subscription-status`,
      payload
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatedPaymentMethodAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(`subscription/set-payment-method`, {
      payment_method_id: payload,
    });
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const markAsPaidApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.put(`invoice/${payload.id}/status`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during marking invoice:", error);
    throw error;
  }
};

export const getPaymentMethodsAPI = async (payload) => {
  try {
    // Call your API endpoint with the constructed URL
    const response = await apis.get(
      "subscription/get-payment-methods",
      payload
    );

    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching payment methods:", error);
    throw error;
  }
};

export const updateDefaultPaymentMethodApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post(
      `subscription/set-default-payment-method`,
      payload
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePaymentMethodAPI = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(
      `subscription/delete-payment-method/${id}`
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during deleteing payment method:", error);
    throw error;
  }
};
