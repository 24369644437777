import apis, { formAxios } from "../index";

export const loginUser = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("login", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during login:", error);
    throw error;
  }
};
export const registerUser = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("register", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during login:", error);
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("forgot-password", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    throw error;
  }
};

export const verifyOtp = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("verify/otp", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during login:", error);
    throw error;
  }
};

export const resendOTPApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("resend/otp", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during login:", error);
    throw error;
  }
};

export const updateProfileInfoApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("user/update-full-profile", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteProfileApi = async () => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("delete-my-account");
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatePasswordApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("update-password", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.post("forgot-password/reset", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMyPermissionsAPI = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await apis.get("my-permissions", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during login:", error);
    throw error;
  }
};
