import { ActionTypes } from "./actions";

const initialState = {
  isPagesLoading: false,
  pages: undefined,
  totalRows: 0,
  selectedPage: undefined,
};

export const pages = (state = initialState, action) => {
  switch (action?.type) {
    case ActionTypes.GET_PAGES_LOADING:
      return {
        ...state,
        isPagesLoading: true,
      };
    case ActionTypes.ADD_PAGES_LOADING:
      return {
        ...state,
        isPagesLoading: true,
      };
    case ActionTypes.GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload?.data,
        totalRows: action.payload?.totalRows,
        isPagesLoading: false,
      };
    case ActionTypes.GET_PAGES_ERROR:
      return {
        ...state,
        isPagesLoading: false,
      };
    case ActionTypes.GET_PAGE_BY_SLUG_SUCCESS:
      return {
        ...state,
        isPagesLoading: false,
        selectedPage: action.payload,
      };
    case ActionTypes.GET_PAGE_BY_SLUG_ERROR:
      return {
        ...state,
        isPagesLoading: false,
      };
    case ActionTypes?.ADD_PAGES_SUCCESS:
      return {
        ...state,
        isPagesLoading: false,
        pages: [action.payload, ...state.pages],
      };
    case ActionTypes?.UPDATE_PAGES_SUCCESS:
      return {
        ...state,
        isPagesLoading: false,
        pages: state.pages?.map((dat) =>
          dat?.slug === action?.payload?.slug ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_PAGES_SUCCESS:
      return {
        ...state,
        isPagesLoading: false,
        pages: state.pages?.filter((dat) => dat?.slug !== action?.payload),
      };
    case ActionTypes.DELETE_PAGES_ERROR:
      return {
        ...state,
        isPagesLoading: false,
      };
    case ActionTypes.ADD_PAGES_ERROR:
      return {
        ...state,
        isPagesLoading: false,
      };
    case ActionTypes.UPDATE_PAGES_ERROR:
      return {
        ...state,
        isPagesLoading: false,
      };
    default:
      return state;
  }
};
