import apis, { formAxios } from "../index";

export const getPostsApi = async (payload) => {
  try {
    const response = await apis.get(
      `posts?filters[type]=post&paginate=1&page=${payload?.page}&pagination_size=${payload?.pageSize}`,
      payload
    );
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching pots:", error);
    throw error;
  }
};

export const getPostBySlugApi = async (slug) => {
  try {
    const response = await apis.get(`posts/${slug}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching pots:", error);
    throw error;
  }
};

export const addPostApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("posts", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching posts:", error);
    throw error;
  }
};

export const updatePostApi = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post(`posts/${id}`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during updaing posts:", error);
    throw error;
  }
};
export const postApproveApi = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.put(`posts/${id}/approval-status`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during updaing posts:", error);
    throw error;
  }
};

export const deletePostApi = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(`posts/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during deleting posts:", error);
    throw error;
  }
};
