import { lazy } from "react";

const routes = [
  {
    path: "/",
    exact: true,
    element: lazy(() => import("../pages/account/Login")),
  },
  {
    path: "/forgotten-password",
    element: lazy(() => import("../pages/account/ForgotPassword")),
  },
  {
    path: "/reset-password",
    element: lazy(() => import("../pages/account/ResetPassword")),
  },
  {
    path: "/sign-up",
    element: lazy(() => import("../pages/account/SignUp")),
  },
  {
    path: "/verify-otp",
    element: lazy(() => import("../pages/account/verifyOtp")),
  },
  {
    path: "*",
    element: lazy(() => import("../components/NotFound")),
  },
];

export default routes;
