import {
  addProductsAPI,
  deleteProductsAPI,
  getProductsAPI,
  updateProductsAPI,
  getSkuAPI,
} from "../../apis/services/productsApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_PRODUCTS_LOADING: "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  ADD_PRODUCT_LOADING: "ADD_PRODUCT_LOADING",
  ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_LOADING: "UPDATE_PRODUCT_LOADING",
  UPDATE_PRODUCT_ERROR: "UPDATE_PRODUCT_ERROR",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_LOADING: "DELETE_PRODUCT_LOADING",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_ERROR: "DELETE_PRODUCT_ERROR",
  GET_SKU_LOADING: "GET_SKU_LOADING",
  GET_SKU_ERROR: "GET_SKU_ERROR",
  GET_SKU_SUCCESS: "GET_SKU_SUCCESS",
};

export const getSku = (data, callbeck, setFieldValue) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SKU_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    getSkuAPI(data)
      .then((response) => {
        const sku = response.data?.sku;
        callbeck(sku, setFieldValue);
        // dispatch({
        //   type: ActionTypes.GET_SKU_SUCCESS,
        //   payload: sku,
        // })
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.GET_SKU_ERROR });
      });
  };
};

export const getProducts = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PRODUCTS_LOADING });
    getProductsAPI(payload)
      .then((response) => {
        const data = response.data?.subscriptions?.data || [];
        const totalRows = response.data?.subscriptions?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_PRODUCTS_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_PRODUCTS_ERROR });
      });
  };
};

export const addProduct = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PRODUCTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addProductsAPI(data)
      .then((response) => {
        const addedProduct = response.data?.subscription;
        dispatch({
          type: ActionTypes.ADD_PRODUCT_SUCCESS,
          payload: addedProduct,
        });
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_PRODUCT_ERROR });
      });
  };
};

export const updateProduct = (data, id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PRODUCTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateProductsAPI(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);

        const updatedProduct = response.data?.subscription;
        dispatch({
          type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
          payload: updatedProduct,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_PRODUCT_ERROR });
      });
  };
};
export const deleteProduct = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PRODUCTS_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    deleteProductsAPI(id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);

        dispatch({
          type: ActionTypes.DELETE_PRODUCT_SUCCESS,
          payload: { id },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.DELETE_PRODUCT_ERROR });
      });
  };
};
