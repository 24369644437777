const initialState = {
  brands: [],
  cities: [],
  isLoading: false,
};

export const feature = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FEATURED_BRAND_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_FEATURED_BRAND_SUCCESS":
      return {
        ...state,
        isLoading: false,
        brands: action.payload,
      };
    case "GET_FEATURED_BRAND_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_FEATURED_CITIES_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_FEATURED_CITIES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        cities: action.payload,
      };
    case "GET_FEATURED_CITIES_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_FEATURED_BRAND_SUCCESS":
      return {
        ...state,
        isLoading: false,
        brands: state.brands.map((brand) =>
          brand.id === action.payload?.id
            ? action.payload
            : { ...brand, is_featured: 0 }
        ),
      };
    case "UPDATE_FEATURED_CITIES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        cities: state.cities.map((city) =>
          city.id === action.payload?.id
            ?  action.payload
            : { ...city, is_featured: 0 }
        ),
      };
    default:
      return state;
  }
};

export const selectFeaturedLoading = (state) => state.feature.isLoading;