import { ActionTypes } from "./actions";

const initialState = {
  shots: [],
  isLoading: false,
  isShotDistributionLoading: false,
  applications: [],
  shotDistributions: [],
  totalRows: 0,
};

export const shot = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SHOTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ADD_SHOT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes?.SHOT_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.GET_SHOTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.ADD_SHOT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.GET_SHOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shots: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case ActionTypes?.GET_SHOTS_SLUG_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes?.GET_SHOTS_SLUG_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.GET_SHOTS_SLUG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applications: action.payload?.data,
        totalRows: action.payload?.totalRows,
      };
    case ActionTypes?.UPDATE_SHOTS_SLUG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applications: state.applications?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case ActionTypes?.ADD_SHOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shots: [action.payload, ...state.shots],
      };
    case ActionTypes?.UPDATE_SHOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shots: state.shots?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_SHOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shots: state.shots?.filter((dat) => dat?.slug !== action?.payload),
      };
    case ActionTypes?.UPDATE_SHOT_FEATURED:
      return {
        ...state,
        isLoading: false,
        shots: state.shots.map((shot) =>
          shot.id === action.payload?.id
            ? action?.payload
            : { ...shot, is_featured: 0 }
        ),
      };
    case ActionTypes.GET_SHOT_DISTRIBUTION_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes?.GET_SHOT_DISTRIBUTION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes?.GET_SHOT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shotDistributions: action.payload,
      };
    case ActionTypes.UPDATE_SHOT_DISTRIBUTION_LOADING:
      return {
        ...state,
        isShotDistributionLoading: true,
      };
    case ActionTypes?.UPDATE_SHOT_DISTRIBUTION_ERROR:
      return {
        ...state,
        isShotDistributionLoading: false,
      };
    case ActionTypes?.UPDATE_SHOT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        isShotDistributionLoading: false,
      };
    case ActionTypes.GET_NOTIFICATION_LOADING:
      return {
        ...state,
        isNotificationLoading: true,
      };
    case ActionTypes?.GET_NOTIFICATION_ERROR:
      return {
        ...state,
        isNotificationLoading: false,
      };
    case ActionTypes?.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isNotificationLoading: false,
        notifications: action.payload,
      };
    case ActionTypes.UPDATE_NOTIFICATION_LOADING:
      return {
        ...state,
        isNotificationLoading: true,
      };
    case ActionTypes?.UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        isNotificationLoading: false,
      };
    case ActionTypes?.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isNotificationLoading: false,
      };
    default:
      return state;
  }
};

export const selectShotLoading = (state) => state.shot.isLoading;
