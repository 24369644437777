import { ActionTypes } from "./actions";

const initialState = {
  isMediaLoading: false,
  media: undefined,
  totalRows: 0,
  selectedMedia: undefined,
};

export const medias = (state = initialState, action) => {
  switch (action?.type) {
    case ActionTypes.GET_MEDIA_LOADING:
      return {
        ...state,
        isMediaLoading: true,
      };
    case ActionTypes.ADD_MEDIA_LOADING:
      return {
        ...state,
        isMediaLoading: true,
      };
    case ActionTypes.GET_MEDIA_SUCCESS:
      return {
        ...state,
        media: action.payload?.data,
        totalRows: action.payload?.totalRows,
        isMediaLoading: false,
      };
    case ActionTypes.GET_MEDIA_ERROR:
      return {
        ...state,
        isMediaLoading: false,
      };
    case ActionTypes.GET_MEDIA_BY_SLUG_SUCCESS:
      return {
        ...state,
        isMediaLoading: false,
        selectedPage: action.payload,
      };
    case ActionTypes.GET_MEDIA_BY_SLUG_ERROR:
      return {
        ...state,
        isMediaLoading: false,
      };
    case ActionTypes?.ADD_MEDIA_SUCCESS:
      return {
        ...state,
        isMediaLoading: false,
        media: [action.payload, ...state.media],
      };
    case ActionTypes?.UPDATE_MEDIA_SUCCESS:
      return {
        ...state,
        isMediaLoading: false,
        media: state.media?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        isMediaLoading: false,
        media: state.media?.filter((dat) => dat?.id !== action?.payload),
      };
    case ActionTypes.DELETE_MEDIA_ERROR:
      return {
        ...state,
        isMediaLoading: false,
      };
    case ActionTypes.ADD_MEDIA_ERROR:
      return {
        ...state,
        isMediaLoading: false,
      };
    case ActionTypes.UPDATE_MEDIA_ERROR:
      return {
        ...state,
        isMediaLoading: false,
      };
    default:
      return state;
  }
};
