const initialState = {
  dropDownList: [],
  isLoading: false,
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMMON_DROPDOWN_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_COMMON_DROPDOWN_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_COMMON_DROPDOWN_SUCCESS":
      return {
        ...state,
        dropDownList: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
