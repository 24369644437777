import {
  addHubsAPI,
  deleteHubsAPI,
  getHubsAPI,
  updateHubsAPI,
} from "../../apis/services/hubsApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_HUBS_LOADING: "GET_HUBS_LOADING",
  GET_HUBS_ERROR: "GET_HUBS_ERROR",
  GET_HUBS_SUCCESS: "GET_HUBS_SUCCESS",
  ADD_HUB_LOADING: "ADD_HUB_LOADING",
  ADD_HUB_ERROR: "ADD_HUB_ERROR",
  ADD_HUB_SUCCESS: "ADD_HUB_SUCCESS",
  UPDATE_HUB_LOADING: "UPDATE_HUB_LOADING",
  UPDATE_HUB_ERROR: "UPDATE_HUB_ERROR",
  UPDATE_HUB_SUCCESS: "UPDATE_HUB_SUCCESS",
  DELETE_HUB_LOADING: "DELETE_HUB_LOADING",
  DELETE_HUB_SUCCESS: "DELETE_HUB_SUCCESS",
  DELETE_HUB_ERROR: "DELETE_HUB_ERROR",
};

export const getHubs = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_HUBS_LOADING });
    getHubsAPI(payload)
      .then((response) => {
        const hubsData = response.data?.hubs?.data || [];
        const totalRows = response.data?.hubs?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_HUBS_SUCCESS,
          payload: { hubsData, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_HUBS_ERROR });
      });
  };
};
export const addHubs = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_HUB_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addHubsAPI(data)
      .then((response) => {
        const updatedHubs = response?.data?.hub;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.ADD_HUB_SUCCESS,
          payload: updatedHubs,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_HUB_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const updateHub = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_HUB_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updateHubsAPI(data, id)
      .then((response) => {
        const hubData = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_HUB_SUCCESS,
          payload: hubData?.hub,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_HUB_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const deleteHub = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_HUB_LOADING });
    deleteHubsAPI(id)
      .then((response) => {
        const hubId = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_HUB_SUCCESS,
          payload: id,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_HUB_ERROR });
      });
  };
};
