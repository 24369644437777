const initialState = {
  demographic: [],
  demographicLoading: true,
  shotReport: [],
  shotReportLoading: true,
  hubReport: [],
  hubReportLoading: true,
  applicationReport: [],
  applicationLoading: true,
};

export const reports = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DEMOGRAPHIC_REPORT_LOADING":
      return {
        ...state,
        demographicLoading: true,
      };
    case "GET_DEMOGRAPHIC_REPORT_ERROR":
      return {
        ...state,
        demographicLoading: false,
      };
    case "GET_DEMOGRAPHIC_REPORT_SUCCESS":
      return {
        ...state,
        demographicLoading: false,
        demographic: action.payload,
      };
    case "GET_SHOT_REPORT_LOADING":
      return {
        ...state,
        shotReportLoading: true,
      };
    case "GET_SHOT_REPORT_ERROR":
      return {
        ...state,
        shotReportLoading: false,
      };
    case "GET_SHOT_REPORT_SUCCESS":
      return {
        ...state,
        shotReportLoading: false,
        shotReport: action.payload,
      };
    case "GET_HUB_REPORT_LOADING":
      return {
        ...state,
        hubReportLoading: true,
      };
    case "GET_HUB_REPORT_ERROR":
      return {
        ...state,
        hubReportLoading: false,
      };
    case "GET_HUB_REPORT_SUCCESS":
      return {
        ...state,
        hubReportLoading: false,
        hubReport: action.payload,
      };
    case "GET_APPLICATION_REPORT_LOADING":
      return {
        ...state,
        applicationLoading: true,
      };
    case "GET_APPLICATION_REPORT_ERROR":
      return {
        ...state,
        applicationLoading: false,
      };
    case "GET_APPLICATION_REPORT_SUCCESS":
      return {
        ...state,
        applicationLoading: false,
        applicationReport: action.payload,
      };
    default:
      return state;
  }
};
