import { ActionTypes } from "./actions";

const initialState = {
  hubs: [],
  totalRows: 0,
  isListLoading:false,
  isHubLoading: false,
};

export const hub = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_HUBS_LOADING:
      return {
        ...state,
        isListLoading: true,
      };
    case ActionTypes.ADD_HUB_LOADING:
      return {
        ...state,
        isHubLoading: true,
      };
    case ActionTypes?.GET_HUBS_ERROR:
      return {
        ...state,
        isListLoading: false,
      };
    case ActionTypes?.ADD_HUB_ERROR:
      return {
        ...state,
        isHubLoading: false,
      };
    case ActionTypes?.GET_HUBS_SUCCESS:
      return {
        ...state,
        hubs: action.payload?.hubsData,
        totalRows: action.payload?.totalRows,
        isListLoading: false,
      };
    case ActionTypes?.ADD_HUB_SUCCESS:
      return {
        ...state,
        isHubLoading: false,
        hubs: [action.payload, ...state.hubs],
      };
    case ActionTypes?.UPDATE_HUB_SUCCESS:
      return {
        ...state,
        isHubLoading: false,
        hubs: state.hubs?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    case ActionTypes?.DELETE_HUB_SUCCESS:
      return {
        ...state,
        isHubLoading: false,
        hubs: state.hubs?.map((dat) =>
          dat?.id === action?.payload?.id ? action.payload : dat
        ),
      };
    default:
      return state;
  }
};
