import {
  activateSubscriptionApi,
  cancelSubscriptionApi,
  changePlanAPI,
  deletePaymentMethodAPI,
  getIntentApi,
  getInvoicesApi,
  getPaymentMethodsAPI,
  getSubscriptionListApi,
  getSubscriptionPlansAPI,
  markAsPaidApi,
  sendInvoiceAPI,
  updateDefaultPaymentMethodApi,
  updatedPaymentMethodAPI,
} from "../../apis/services/paymentApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  OPEN_PAYMENT_MODAL: "OPEN_PAYMENT_MODAL",
  CLOSE_PAYMENT_MODAL: "CLOSE_PAYMENT_MODAL",
  GET_SUBSCRIPTIONS_LOADING: "GET_SUBSCRIPTIONS_LOADING",
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_ERROR: "GET_SUBSCRIPTIONS_ERROR",
  SEND_INVOICE_LOADING: "SEND_INVOICE_LOADING",
  SEND_INVOICE_SUCCESS: "SEND_INVOICE_SUCCESS",
  SEND_INVOICE_ERROR: "SEND_INVOICE_ERROR",
  UPDATE_PAYMENT_STATE: "UPDATE_PAYMENT_STATE",
  GET_INTENT_LOADING: "GET_INTENT_LOADING",
  GET_INTENT_SUCCESS: "GET_INTENT_SUCCESS",
  GET_INTENT_ERROR: "GET_INTENT_ERROR",
  GET_SUBSCRIPTIONS_LIST_LOADING: "GET_SUBSCRIPTIONS_LIST_LOADING",
  GET_SUBSCRIPTIONS_LIST_SUCCESS: "GET_SUBSCRIPTIONS_LIST_SUCCESS",
  GET_SUBSCRIPTIONS_LIST_ERROR: "GET_SUBSCRIPTIONS_LIST_ERROR",
  GET_INVOICES_LIST_LOADING: "GET_INVOICES_LIST_LOADING",
  GET_INVOICES_LIST_SUCCESS: "GET_INVOICES_LIST_SUCCESS",
  GET_INVOICES_LIST_ERROR: "GET_INVOICES_LIST_ERROR",
  CANCEL_SUBSCRIPTION_LOADING: "CANCEL_SUBSCRIPTION_LOADING",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_ERROR: "CANCEL_SUBSCRIPTION_ERROR",
  MARK_AS_PAID_LOADING: "MARK_AS_PAID_LOADING",
  MARK_AS_PAID_SUCCESS: "MARK_AS_PAID_SUCCESS",
  MARK_AS_PAID_ERROR: "MARK_AS_PAID_ERROR",
  ACTIVATE_SUBSCRIPTION_LOADING: "ACTIVATE_SUBSCRIPTION_LOADING",
  ACTIVATE_SUBSCRIPTION_SUCCESS: "ACTIVATE_SUBSCRIPTION_SUCCESS",
  ACTIVATE_SUBSCRIPTION_ERROR: "ACTIVATE_SUBSCRIPTION_ERROR",
  GET_PAYMENT_METHOD_LIST_LOADING: "GET_PAYMENT_METHOD_LIST_LOADING",
  GET_PAYMENT_METHOD_LIST_SUCCESS: "GET_PAYMENT_METHOD_LIST_SUCCESS",
  GET_PAYMENT_METHOD_LIST_ERROR: "GET_PAYMENT_METHOD_LIST_ERROR",
  UPDATE_DEFAULT_PAYMENT_METHOD_LOADING:
    "UPDATE_DEFAULT_PAYMENT_METHOD_LOADING",
  UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS:
    "UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS",
  UPDATE_DEFAULT_PAYMENT_METHOD_ERROR: "UPDATE_DEFAULT_PAYMENT_METHOD_ERROR",
  SEND_CHANGE_PLAN_LOADING: "SEND_CHANGE_PLAN_LOADING",
  SEND_CHANGE_PLAN_SUCCESS: "SEND_CHANGE_PLAN_SUCCESS",
  SEND_CHANGE_PLAN_ERROR: "SEND_CHANGE_PLAN_ERROR",
  UPDATE_PAYMENT_METHOD_LOADING: "UPDATE_PAYMENT_METHOD_LOADING",
  UPDATE_PAYMENT_METHOD_SUCCESS: "UPDATE_PAYMENT_METHOD_SUCCESS",
  UPDATE_PAYMENT_METHOD_ERROR: "UPDATE_PAYMENT_METHOD_ERROR",
  DELETE_PAYMENT_METHOD_LOADING: "DELETE_PAYMENT_METHOD_LOADING",
  DELETE_PAYMENT_METHOD_SUCCESS: "DELETE_PAYMENT_METHOD_SUCCESS",
  DELETE_PAYMENT_METHOD_ERROR: "DELETE_PAYMENT_METHOD_ERROR",
};

export const openPaymentModal = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.OPEN_PAYMENT_MODAL });
  };
};

export const closePaymentModal = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLOSE_PAYMENT_MODAL });
  };
};

export const updatePaymentState = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PAYMENT_STATE, payload: data });
  };
};

export const getSubscriptionPlans = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SUBSCRIPTIONS_LOADING });
    getSubscriptionPlansAPI(categoryId)
      .then((response) => {
        const data = response.data?.subscriptions?.data || [];
        dispatch({
          type: ActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SUBSCRIPTIONS_ERROR });
      });
  };
};

export const sendInvoice = (arg, callback) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SEND_INVOICE_LOADING });
    try {
      const response = await sendInvoiceAPI(arg);
      const data = response.data || [];
      dispatch({
        type: ActionTypes.SEND_INVOICE_SUCCESS,
        payload: data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.SEND_INVOICE_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};

export const changePlan = (arg, callback) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SEND_CHANGE_PLAN_LOADING });
    try {
      const response = await changePlanAPI(arg);
      const data = response.data || [];
      dispatch({
        type: ActionTypes.SEND_CHANGE_PLAN_SUCCESS,
        payload: data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.SEND_CHANGE_PLAN_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};

export const getIntent = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_INTENT_LOADING });
    getIntentApi()
      .then((response) => {
        const intentData = response.data?.intent || [];
        dispatch({
          type: ActionTypes.GET_INTENT_SUCCESS,
          payload: intentData,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_INTENT_ERROR });
      });
  };
};

export const updatedPaymentMethodAsync = (arg, callback) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PAYMENT_METHOD_LOADING });
    try {
      const response = await updatedPaymentMethodAPI(arg);
      const data = response.data?.paymentMethods || [];
      CommonToast.notify("success", response?.data?.message);
      dispatch({
        type: ActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
        payload: data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      if (callback) {
        callback();
      }
      dispatch({ type: ActionTypes.UPDATE_PAYMENT_METHOD_ERROR });
    }
  };
};

export const deletePaymentMethod = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_PAYMENT_METHOD_LOADING });
    deletePaymentMethodAPI(id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_PAYMENT_METHOD_SUCCESS,
          payload: id,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (callback) {
          callback();
        }
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_PAYMENT_METHOD_ERROR });
      });
  };
};

export const getSubscriptions = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SUBSCRIPTIONS_LIST_LOADING });
    getSubscriptionListApi(payload)
      .then((response) => {
        const data = response.data?.organisations?.data || [];
        const totalRows = response.data?.organisations?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_SUBSCRIPTIONS_LIST_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_SUBSCRIPTIONS_LIST_ERROR });
      });
  };
};

export const getPaymentMethods = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PAYMENT_METHOD_LIST_LOADING });
    getPaymentMethodsAPI(payload)
      .then((response) => {
        const data = response.data?.payment_methods || [];
        dispatch({
          type: ActionTypes.GET_PAYMENT_METHOD_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_PAYMENT_METHOD_LIST_ERROR });
      });
  };
};

export const getInvoices = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_INVOICES_LIST_LOADING });
    getInvoicesApi(payload)
      .then((response) => {
        const data = response.data?.invoices?.data || [];
        const totalRows = response.data?.invoices?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_INVOICES_LIST_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_INVOICES_LIST_ERROR });
      });
  };
};

export const cancelSubscription = (arg, callback) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CANCEL_SUBSCRIPTION_LOADING });
    try {
      const response = await cancelSubscriptionApi(arg);
      const data = response.data || [];
      CommonToast.notify("success", response?.data?.message);
      dispatch({
        type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.CANCEL_SUBSCRIPTION_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};

export const activateSubscription = (arg) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ACTIVATE_SUBSCRIPTION_LOADING });
    try {
      const response = await activateSubscriptionApi(arg);
      const data = response.data || [];
      CommonToast.notify("success", response?.data?.message);
      dispatch({
        type: ActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.ACTIVATE_SUBSCRIPTION_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};

export const markAsPaidAsync = (arg, callback) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.MARK_AS_PAID_LOADING });
    try {
      const response = await markAsPaidApi(arg);
      const data = response.data || [];
      CommonToast.notify("success", response?.data?.message);
      dispatch({
        type: ActionTypes.MARK_AS_PAID_SUCCESS,
        payload: data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.MARK_AS_PAID_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};

export const updateDefaultPaymentMethod = (arg) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_DEFAULT_PAYMENT_METHOD_LOADING });
    try {
      const response = await updateDefaultPaymentMethodApi(arg);
      const data = response.data?.payment_methods || [];
      CommonToast.notify("success", response?.data?.message);
      dispatch({
        type: ActionTypes.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      CommonToast.notify(
        "error",
        error.response?.data.message || error.message
      );
      dispatch({ type: ActionTypes.UPDATE_DEFAULT_PAYMENT_METHOD_ERROR });
      throw error; // Re-throw the error to handle it in the component
    }
  };
};
