import { addPageApi, deletePageApi, getPageBySlugApi, getPagesApi, pageApproveApi, updatePageApi } from "../../apis/services/pagesApi";
import CommonToast from "../../components/toastContainer";

export const ActionTypes = {
  GET_PAGES_LOADING: "GET_PAGES_LOADING",
  GET_PAGES_ERROR: "GET_PAGES_ERROR",
  GET_PAGES_SUCCESS: "GET_PAGES_SUCCESS",
  ADD_PAGES_LOADING: "ADD_PAGES_LOADING",
  ADD_PAGES_ERROR: "ADD_PAGES_ERROR",
  ADD_PAGES_SUCCESS: "ADD_PAGES_SUCCESS",
  UPDATE_PAGES_LOADING: "UPDATE_PAGES_LOADING",
  UPDATE_PAGES_ERROR: "UPDATE_PAGES_ERROR",
  UPDATE_PAGES_SUCCESS: "UPDATE_PAGES_SUCCESS",
  DELETE_PAGES_LOADING: "DELETE_PAGES_LOADING",
  DELETE_PAGES_SUCCESS: "DELETE_PAGES_SUCCESS",
  DELETE_PAGES_ERROR: "DELETE_PAGES_ERROR",
  GET_PAGE_BY_SLUG_LOADING: "GET_PAGE_BY_SLUG_LOADING",
  GET_PAGE_BY_SLUG_ERROR: "GET_PAGE_BY_SLUG_ERROR",
  GET_PAGE_BY_SLUG_SUCCESS: "GET_PAGE_BY_SLUG_SUCCESS",
};

export const getPages = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PAGES_LOADING });
    getPagesApi(payload)
      .then((response) => {
        const data = response.data?.posts?.data || [];
        const totalRows = response.data?.pages?.meta?.total || [];
        dispatch({
          type: ActionTypes.GET_PAGES_SUCCESS,
          payload: { data, totalRows },
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_PAGES_ERROR });
      });
  };
};

export const getPageBySlug = (slug) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PAGES_LOADING });
    getPageBySlugApi(slug)
      .then((response) => {
        const data = response.data?.page || [];
        dispatch({
          type: ActionTypes.GET_PAGE_BY_SLUG_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.GET_PAGES_ERROR });
      });
  };
};

export const addPage = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_PAGES_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    addPageApi(data)
      .then((response) => {
        const addedPage = response.data?.post;
        dispatch({
          type: ActionTypes.ADD_PAGES_SUCCESS,
          payload: addedPage,
        });
        if (callback) {
          callback();
        }
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        if (callback) {
          callback(true);
        }
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.ADD_PAGES_ERROR });
      });
  };
};

export const updatePage = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_PAGES_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    updatePageApi(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        const updatedPage = response.data?.post;
        dispatch({
          type: ActionTypes.UPDATE_PAGES_SUCCESS,
          payload: updatedPage,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_PAGES_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};
export const approvePage = (data, id, callback) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_PAGES_LOADING });
    // navigate('/dashboard')LOGIN_SUCCESS
    pageApproveApi(data, id)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        const updatedPage = response.data?.page;
        dispatch({
          type: ActionTypes.UPDATE_PAGES_SUCCESS,
          payload: updatedPage,
        });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.UPDATE_PAGES_ERROR });
        if (callback) {
          callback(true);
        }
      });
  };
};

export const deletePage = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PAGES_LOADING });
    deletePageApi(data?.slug)
      .then((response) => {
        const postId = response.data;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.DELETE_PAGES_SUCCESS,
          payload: data?.slug,
        });
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_PAGES_ERROR });
      });
  };
};
