import { generateURL } from "../../helpers/commonUtils";
import apis, { formAxios } from "../index";

export const getMediaApi = async (payload) => {
  try {
    let url = generateURL("media", payload);

    const response = await apis.get(url, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching media:", error);
    throw error;
  }
};

export const getMediaByIdApi = async (id) => {
  try {
    const response = await apis.get(`media/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching media:", error);
    throw error;
  }
};

export const addMediaApi = async (payload) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post("media", payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during fetching media:", error);
    throw error;
  }
};

export const updateMediaApi = async (payload, id) => {
  try {
    // Call your login API endpoint here
    const response = await formAxios.post(`media/${id}`, payload);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during updaing media:", error);
    throw error;
  }
};

export const deleteMediaApi = async (id) => {
  try {
    // Call your login API endpoint here
    const response = await apis.delete(`media/${id}`);
    const data = await response;
    // Return data or handle response as needed
    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error during deleting media:", error);
    throw error;
  }
};
