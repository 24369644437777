// rootReducer.js
import { combineReducers } from "redux";
import { auth } from "./auth/reducer";
import { category } from "./categories/reducer";
import { activities } from "./activities/reducer";
import { passion } from "./passions/reducer";
import { product } from "./products/reducer";
import { organization } from "./organization/reducer";
import { hub } from "./hub/reducer";
import { shot } from "./shots/reducer";
import { users } from "./userManagement/reducer";
import { feature } from "./featured/reducer";
import { reports } from "./reports/reducer";
import { payment } from "./payment/reducer";
import { dash } from "./dashboard/reducer";
import { posts } from "./posts/reducer";
import { common } from "./common/reducer";
import { pages } from "./pages/reducer";
import { medias } from "./media/reducer";

const appReducer = combineReducers({
  auth: auth,
  category,
  activities,
  product,
  organization,
  hub,
  shot,
  users,
  passion,
  feature,
  reports,
  payment,
  dash,
  posts,
  common,
  pages,
  medias
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STORE") return appReducer(undefined, action);
  else return appReducer(state, action);
};

export default rootReducer;
