const initialState = {
  activities: [],
  isLoading: false,
  totalRows: 0,
  isListLoading: false,
};

export const activities = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACTIVITIES_LOADING":
      return {
        ...state,
        isListLoading: true,
      };
    case "GET_ACTIVITIES_ERROR":
      return {
        ...state,
        isListLoading: false,
      };
    case "GET_ACTIVITIES_SUCCESS":
      return {
        ...state,
        activities: action.payload?.data,
        totalRows: action.payload?.totalRows,
        isListLoading: false,
      };
    case "ADD_ACTIVITIES_SUCCESS":
      return {
        ...state,
        activities: [action.payload, ...state.activities],
        isLoading: false,
        isListLoading: false,

      };
    case "UPDATE_ACTIVITIES_SUCCESS":
      return {
        ...state,
        activities: state.activities.map((activity) =>
          activity.id === action.payload.id ? action.payload : activity
        ),
        isLoading: false,
        isListLoading: false,

      };
    case "ARCHIVE_ACTIVITIES_SUCCESS":
      return {
        ...state,
        activities: state.activities.map((activity) =>
          activity.id === action.payload.id
            ? { ...activity, is_archived: activity?.is_archived === 1 ? 0 : 1 }
            : activity
        ),
        isLoading: false,
        isListLoading:false
      };
    default:
      return state;
  }
};
